var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buyPrice" },
    [
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item, index) {
                  return item.show
                    ? _c("div", { key: index, staticClass: "cell" }, [
                        _c("div", { staticClass: "line" }),
                        _c("div", { staticClass: "order" }, [
                          _c("div", { staticClass: "flex-between" }, [
                            _c("div", { staticClass: "flex" }, [
                              item.status == "WAIT_FETCH"
                                ? _c("div", { staticClass: "icon" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.checkList,
                                          expression: "checkList"
                                        }
                                      ],
                                      staticClass: "checkBox",
                                      attrs: {
                                        type: "checkbox",
                                        name: "checkBoxTest"
                                      },
                                      domProps: {
                                        value: item,
                                        checked: Array.isArray(_vm.checkList)
                                          ? _vm._i(_vm.checkList, item) > -1
                                          : _vm.checkList
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$a = _vm.checkList,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = item,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.checkList = $$a.concat([
                                                    $$v
                                                  ]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.checkList = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.checkList = $$c
                                            }
                                          },
                                          _vm.checkBox
                                        ]
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _c("h3", [
                                _vm._v(" " + _vm._s(item.goods[0].name))
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "commonBorder ashBorder",
                                class: {
                                  greenBorder:
                                    item.status == "DONE" ||
                                    item.status == "BUY_VIOLATE"
                                      ? true
                                      : false,
                                  redBorder:
                                    item.status == "WAIT_FETCH_AUDIT" ||
                                    item.status == "WAIT_FETCH_FINISHED"
                                      ? true
                                      : false,
                                  blueBorder:
                                    item.status == "WAIT_FETCH" ? true : false,
                                  yellowBorder:
                                    item.status == "WAIT_PAYED" ? true : false
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.status == "SUSPENSE"
                                        ? "待审核"
                                        : item.status == "WAIT_FETCH_AUDIT"
                                        ? "取料审核中"
                                        : item.status == "WAIT_FETCH"
                                        ? "待取料"
                                        : item.status == "DONE" ||
                                          item.status == "BUY_VIOLATE"
                                        ? "已完成"
                                        : item.status == "WAIT_FETCH_FINISHED"
                                        ? "取料中"
                                        : item.status == "WAIT_PAYED"
                                        ? "待付款"
                                        : item.status == "UNDELIVERED"
                                        ? "未交付"
                                        : item.status == "RECEIVE"
                                        ? "已交付"
                                        : item.status == "CANCEL"
                                        ? "已取消"
                                        : "未通过"
                                    )
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "info" }, [
                            item.hasOwnProperty("businessType")
                              ? _c("div", { staticClass: "weight flex padd" }, [
                                  _c("div", { staticClass: "size colore1" }, [
                                    _vm._v("业务类型")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "size marginLeft color7",
                                      staticStyle: { marginLeft: "6px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.businessType == "0"
                                            ? "即期提料"
                                            : item.businessType == "3"
                                            ? "延期小克重提料"
                                            : "延期提料"
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "weight flex padd" }, [
                              _c("div", { staticClass: "size colore1" }, [
                                _vm._v("克重（克）")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "size marginLeft color7",
                                  staticStyle: { marginLeft: "6px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.goods[0].weight.toFixed(2)) +
                                      "克"
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "price flex padd" }, [
                              _c("div", { staticClass: "size colore1" }, [
                                _vm._v("单价（元/克）")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "size marginLeft color7",
                                  staticStyle: { marginLeft: "6px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.goods[0].price.toFixed(
                                          item.goods[0].name.includes("白银")
                                            ? 3
                                            : 2
                                        )
                                      ) +
                                      "元/克"
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "time flex padd" }, [
                              _c("div", { staticClass: "size colore1" }, [
                                _vm._v("下单时间")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "size marginLeft color7",
                                  staticStyle: { marginLeft: "6px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate_")(item.createTime)
                                      )
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "flex-between" }, [
                            _c(
                              "div",
                              { staticClass: "orderInfo colore1 van-ellipsis" },
                              [_vm._v("订单号 : " + _vm._s(item.oid))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "xiangqing",
                                on: {
                                  click: function($event) {
                                    return _vm.TobuyOrderParticulars(item.oid)
                                  }
                                }
                              },
                              [
                                _vm._v("详情 "),
                                _c("div", { staticClass: "icon" })
                              ]
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                }),
                0
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false, "get-container": "#app" },
          model: {
            value: _vm.showBuy,
            callback: function($$v) {
              _vm.showBuy = $$v
            },
            expression: "showBuy"
          }
        },
        [
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.myTakeRadio,
                callback: function($$v) {
                  _vm.myTakeRadio = $$v
                },
                expression: "myTakeRadio"
              }
            },
            [
              _c(
                "div",
                { staticClass: "takeMode" },
                [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("van-radio", {
                        attrs: {
                          "checked-color": "#39394d",
                          "icon-size": "12px",
                          name: "myTake"
                        }
                      })
                    ],
                    1
                  ),
                  _c("van-cell", {
                    attrs: { "is-link": "", border: false },
                    on: {
                      click: function($event) {
                        return _vm.PickUpMethod("myTake")
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "custom-title" }, [
                              _vm._v("到店自取")
                            ]),
                            _c("span", [_vm._v("(请选择取货中心)")])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("div", { staticClass: "addres flex" }, [
                    _c(
                      "div",
                      [_c("van-icon", { attrs: { name: "location" } })],
                      1
                    ),
                    _c("div", [
                      _c("p", { staticStyle: { fontSize: "14px" } }, [
                        _vm._v(_vm._s(_vm.defPickUpCenter.company))
                      ]),
                      _c("p", { staticStyle: { fontSize: "14px" } }, [
                        _vm._v(_vm._s(_vm.defPickUpCenter.preAddr))
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "takeMode" },
                [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("van-radio", {
                        attrs: {
                          "checked-color": "#39394d",
                          "icon-size": "12px",
                          name: "express"
                        }
                      })
                    ],
                    1
                  ),
                  _c("van-cell", {
                    attrs: { "is-link": "", border: false },
                    on: {
                      click: function($event) {
                        return _vm.PickUpMethod("express")
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "custom-title" }, [
                              _vm._v("快递到家")
                            ]),
                            _c("span", [_vm._v("(请选择收货地址)")])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("div", { staticClass: "addres flex goHome" }, [
                    _c(
                      "div",
                      [_c("van-icon", { attrs: { name: "location" } })],
                      1
                    ),
                    _c("div", { staticStyle: { fontSize: "14px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.isAddress
                            ? _vm.ReceivingGoods.preAddr +
                                _vm.ReceivingGoods.address
                            : "请选择地址"
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { border: false } },
                    [
                      _c("van-field", {
                        attrs: {
                          label: "备注",
                          placeholder: "请尽快发货",
                          center: true,
                          "input-align": "right"
                        },
                        model: {
                          value: _vm.beiZhuVal,
                          callback: function($$v) {
                            _vm.beiZhuVal = $$v
                          },
                          expression: "beiZhuVal"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "flex_between popbtn" }, [
            _c(
              "div",
              { staticClass: "gocer closebtn", on: { click: _vm.close } },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              { staticClass: "gocer confirmbtn", on: { click: _vm.confirm } },
              [_vm._v("确定")]
            )
          ])
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "30%" },
          attrs: {
            position: "bottom",
            "close-on-click-overlay": false,
            "lazy-render": "",
            "get-container": "#app"
          },
          on: { "click-overlay": _vm.overPop },
          model: {
            value: _vm.addresShow,
            callback: function($$v) {
              _vm.addresShow = $$v
            },
            expression: "addresShow"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("选择检测中心")]),
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.radio,
                callback: function($$v) {
                  _vm.radio = $$v
                },
                expression: "radio"
              }
            },
            _vm._l(_vm.Addres, function(item, index) {
              return _c(
                "div",
                { key: item.id, class: { bg: index == _vm.radio, text: true } },
                [
                  _c(
                    "van-radio",
                    {
                      attrs: {
                        name: index,
                        "checked-color": "#39394d",
                        "icon-size": "12px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.radioClose(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "flex-between" }, [
                        _c("h4", [_vm._v(_vm._s(item.company))]),
                        _c("span", [_vm._v(_vm._s(item.phone))])
                      ]),
                      _c("p", [_vm._v(_vm._s(item.preAddr + item.address))])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }