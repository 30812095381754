<template>
    <div class="buyOrderJieSuan">
        <van-pull-refresh v-model="refreshing" style="min-height: 100vh;"  @refresh="onRefresh">
            <template v-if="ishow">
               <van-empty
                       class="custom-image"
                       :image="require('@/assets/images/pic_no information@2x.png')"
                       description="暂无数据!"
                     />
            </template>
             <van-list
               v-model="loading"
               :finished="finished"
               finished-text="没有更多了"
               @load="onLoad"
               v-if="!ishow"
             >
             <!-- 买料违约单 -->
                <div class="cell" v-for="(item) in buyViolate" :key="item.id" @click="toViolate(item)" v-if="item.show">
                    <div class="left">
                      <div class="text" >违约结算</div>
                    </div>
                    <div class="right">
                          <div class="violateOrderInfo">
                                <div class="flex_between">
                                  <h1 class="comeWight">提料重量 {{item.weight}}g</h1>
                                  <div class="status">已完成</div>
                          <!-- <div class="status">{{text}}</div> -->
                                </div>
                                <div class="BrokenWeight">折足重量{{item.weight}}g</div>
                          </div>
                          <div class="orderNmber">
                                <div class="flex_between">
                                      <div class="number van-ellipsis">订单单号:{{item.openNo}} </div>
                                      <div class="time">{{item.created | formatDate_}}</div>
                                </div>
                          </div>
                    </div>
                    <!-- <div class="orderDataInfo">
                                <div class="flex-between">
                      <div class="flex">
                          <h3>黄金金条9999</h3>
                      </div>
                       <div :class="{size:true,daifukuanBg:item.status =='AUDIT'?true:false,yifukaunBg:item.status =='AUDIT'?false:true}">{{item.status =="AUDIT"?'待付款':'已付款' }}</div>
                   </div>
                   <div class="info">
                       <div class="weight flex">
                           <div class="size colore1 infoWidth">总克重（克）</div>
                           <div class="size color7" style="marginLeft:6px">&nbsp;{{item.weight.toFixed(2)}}</div>
                       </div>
                       <div class="price flex">
                           <div class="size colore1 infoWidth">总金额（元/克）</div>
                           <div class="size color7" style="marginLeft:6px">&nbsp; {{item.money}}</div>
                       </div>
                       <div class="time flex">
                           <div class="size colore1 infoWidth">下单时间</div>
                           <div class="size color7" style="marginLeft:6px">&nbsp;{{item.created | formatDate_}}</div>
                       </div>
                   </div>
                   <div class="flex-between">
                       <div class="orderInfo colore1">订单号 : {{item.openNo}}</div>
                       <div class="xiangqing" @click="buyOrderJieSuanDatails(item.id)">详情 ></div>
                   </div>
                    </div> -->
               </div>
             <!-- 买料结算单 -->
               <div class="cell" v-for="(item) in buyJieSuan" :key="item.id" v-if="item.show">
                <div class="line"></div>
                    <div class="orderDataInfo">
                                <div class="flex-between">
                      <div class="flex">
                          <h3>黄金金条9999</h3>
                      </div>
                       <div :class="{daifukuanBg:item.payStatus !='PAYED'?true:false,yifukaunBg:item.payStatus =='PAYED'?true:false}">{{
                        item.payStatus =="WAIT"?'待付款':item.payStatus =="PAYED"?'已付款':'待确认'
                        }}</div>
                   </div>
                   <div class="info">
                       <div class="weight flex">
                           <div class="size colore1 infoWidth">总克重（克）</div>
                           <div class="size color7" style="marginLeft:6px">&nbsp;{{item.weight.toFixed(2)}}</div>
                       </div>
                       <div class="price flex">
                           <div class="size colore1 infoWidth">总金额（元/克）</div>
                           <div class="size color7" style="marginLeft:6px">&nbsp; {{item.money}}</div>
                       </div>
                       <div class="time flex">
                           <div class="size colore1 infoWidth">下单时间</div>
                           <div class="size color7" style="marginLeft:6px">&nbsp;{{item.created | formatDate_}}</div>
                       </div>
                   </div>
                   <div class="flex-between">
                       <div class="orderInfo colore1">订单单号 : {{item.openNo}}</div>
                       <div class="xiangqing" @click="buyOrderJieSuanDatails(item.id)">详情
                         <div class="rightIcon"></div>
                       </div>

                   </div>
                    </div>
               </div>
             </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page:0,
                size:20,
                ishow:false,
                buyViolate:[],//买料违约数据
                buyJieSuan:[],//买料结算数据
              havaData: false,
            }
        },
        methods:{
            toViolate(item){
                    this.$router.push({
                        name:'default',
                        query:{
                            orderid:item.id,
                            payType:'6',
                            type:'buy'
                        }
                    })
            },
            // 详情按钮事件
            buyOrderJieSuanDatails(id){
                this.$router.push({
                    name:'purchaseSettlement',
                    query:{openId:id}
                })
            },
            onLoad() {
                setTimeout(async() => {
                    let res = await api.getBuyOrderJieSuanList({
                        page:this.page,
                        size:this.size,
                        })
                    // 如果加载完数据了 last为true
                    // 把上一段 number赋值给page
                    this.page = res.number +1
                  const nowTime = new Date().getTime()
                  res.content.forEach(item => {
                    const timeDiff = nowTime - item.created * 1000
                    if(Math.floor(timeDiff / (24 * 3600 * 1000)) > 7 && item.status == 'DONE') {
                      item.show = false
                    } else {
                      this.haveData = true
                      item.show = true
                    }
                  })
                        // console.log(res);
                    // 把数组里的每一项一一铺开添加
                    this.list.push(...res.content)
                    if (this.list.length == 0 || !this.haveData) {
                        this.ishow = true
                    }else{
                        this.ishow = false
                        this.dataProcessing(this.list)
                    }

                    // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
                    // 这样才可以继续下次加载
                    this.loading = false;
                    if (res.last == true) {
                        this.finished = true;
                    }
                },1000)

            },
async       onRefresh() {
                this.finished = false
                //请求最新的数据
                let res =  await api.getBuyOrderJieSuanList({
                    page:0,
                    size:this.size,
                   })
                    const nowTime = new Date().getTime()
                    res.content.forEach(item => {
                      const timeDiff = nowTime - item.created * 1000
                      if(Math.floor(timeDiff / (24 * 3600 * 1000)) > 7 && item.status == 'DONE') {
                        item.show = false
                      } else {
                        this.haveData = true
                        item.show = true
                      }
                    })
                    this.page = res.number+1
                    this.list = res.content
                    if (this.list.length== 0 || !this.haveData) {
                            this.ishow = true
                    }else{
                        this.ishow = false
                    }
                    this.refreshing = false
                    this.loading = true
                    this.onLoad()
            },
            dataProcessing(list){
                    // console.log('list',list);
                this.buyViolate =  list.filter(item=>{
                        return item.orderType == "BUY_VIOLATE"
                    })
                this.buyJieSuan = list.filter(item=>{
                        return item.orderType != "BUY_VIOLATE"
                    })
                    // console.log('buyViolate',this.buyViolate);
                    // console.log('buyJieSuan',this.buyJieSuan);
            }
        }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .van-loading__text{
        font-size: 30px;
    }
    .van-list__finished-text{
        font-size: 30px;
    }
    .van-pull-refresh__head{
      font-size:30px !important;
    }
    .van-empty__image{
        width: 420px;
        height: 260px;
    }

}
::v-deep{
    .van-pull-refresh{
        height: 100%;
    }
}
.buyOrderJieSuan{
    padding: 40px;
}
.cell{
    border-radius: 8px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 40px;
    display: flex;
}
.line{
    width: 40px;
    height: 340px;
    background-color: #39394D;
    border-radius: 8px 0px 0px 8px;
}
.orderDataInfo{
    width: 98%;
    padding: 30px 30px;

}
.flex-between{
    display: flex;
    justify-content: space-between;
    h3{
        color: #1a1a1a;
        font-size: 32px;
    }
    .xiangqing{
        color: #999999;
        font-size: 28px;
        width: 100px;
        position: relative;
    }
    .orderInfo{
        line-height: 40px;
    }
}
.flex{
    display: flex;

}
.info{
    margin: 20px 0 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
    .price{
        margin: 6px 0;
    }
}
.color7{
    color: #242424;
}
.colore1{
    color: #999999;
    font-size: 24px;
}
.size{
    font-size: 28px;
}
.daifukuanBg{
    width: 112px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size: 24px;
  color: #FF7A00;
  background: #FFF2E6;
  border-radius: 6px;
}
.yifukaunBg{
  width: 112px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size: 24px;
  color: #00AD8C;
  background:#E6FFFA;
  border-radius: 6px;
}
.infoWidth{
    width: 220px;
}
  .left{
    width: 40px;
    height: 212px;
    background-color: #39394D;
    padding: 46px 0;
    border-radius: 8px 0px 0px 8px;
    .text{
      width: 24px;
      height: 120px;
      font-size: 24px;
      color: #f6d0ab;
      line-height: 30px;
      margin: auto;
    }
  }
  .right{
    width: 100%;
    background-color:#fff;
    height: 212px;
    padding: 0 20px;
    .violateOrderInfo{
        padding: 30px 0;
        border-bottom: 1px solid #E9E9E9;
              .comeWight{
                  color: #242424;
                  font-size: 32px;
                  font-weight: 500;
                  line-height: 42px;
              }
              .status{
                  width: 112px;
                  height: 56px;
                  text-align: center;
                  line-height: 56px;
                  background-color: #E6FFFA;
                  border-radius: 6px;
                  font-size: 24px;
                  color: #00AD8C;
              }
              .BrokenWeight{
                color: #999999;
                font-size: 26px;
                line-height: 34px;
              }
      }
    .orderNmber{
        padding: 20px 0;
        color: #999999;
        font-size: 24px;
        .number{
          width: 320px;
        }
      }
  }
  .flex_between{
    display: flex;
    justify-content: space-between;
  }
  .rightIcon{
      position: absolute;
      bottom: 3px;
      right: 0;
      width: 40px;
      height: 32px;
      background: url("~@/assets/images/btn__grey_more@2x.png") no-repeat;
      background-size: 100% 100%;
    }
</style>
